@charset "UTF-8";


/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies for foundation
@import 'vendor/sassy-lists/helpers/missing-dependencies';
@import 'vendor/sassy-lists/helpers/true';
@import 'vendor/sassy-lists/functions/contain';
@import 'vendor/sassy-lists/functions/purge';
@import 'vendor/sassy-lists/functions/remove';
@import 'vendor/sassy-lists/functions/replace';
@import 'vendor/sassy-lists/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "partials/foundation-settings";

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
// @import 'vendor/foundation/xy-grid/xy-grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
@import 'vendor/foundation/components/button-group';
@import 'vendor/foundation/components/accordion-menu';
@import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/card';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/responsive-embed';
@import 'vendor/foundation/components/label';
@import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
@import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
@import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';
// @import 'vendor/foundation/prototype/prototype';

@mixin foundation-everything(
  $flex: true,
  $prototype: false
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-everything();

@import "partials/fonts";
@import "partials/typography";

// Slick Carousel 1.6.0
@import "vendor/slick/slick";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";


/*=======================================
          HLM Modules
=======================================*/
@import "partials/module-includes";

// Banner styles
@import "modules/banner/_banner_full-width";

// Gallery Options
@import "modules/gallery/ThumbnailLayout";
// @import "modules/gallery/ThumbnailSlider";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";

// HLM Other
@import "partials/accordions";
@import "partials/coupons";
@import "partials/blog-grid";
// @import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/myce-callouts";
@import "partials/subnav";
@import "partials/products";
// @import "partials/food-menus";
@import "partials/search";
@import "partials/sml";

// Enhanced Subpage styles
@import "partials/enhanced-subpage";

// HLM PPC
// @import "ppc/main-ppc";

/*=======================================
      Custom Site Styles
=======================================*/
// Additional styles for default button class
.button{
  font-weight: $global-weight-bold;
  text-transform: uppercase;
  border: 1px solid $secondary-color;

  &:hover, &:focus{
    color: $white;
  }

  // Section buttons
  &.tertiary{
    color: $white;
    border-color: $white;

    &:hover{
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
}

// Home Page logo carousel above main content area
.slider{
  position: relative;
  padding: rem-calc(30 0 20);
  text-align: center;

  hr{
    width: rem-calc(45);
    margin: rem-calc(30) auto;
    border-bottom: 2px solid $secondary-color;
  }

  // Section heading
  .heading{
    color: #333;
    font-family: $header-font-family;
    font-size: rem-calc(38) * 0.7;
    font-weight: 300;

    br{
      display: none;
    }
  }

  // Button below logo slider
  .button{
    margin: rem-calc(50 0 0);
  }

  .row.align-center .row.columns{
    overflow: hidden;
  }

  // Logo slider
  .logo-carousel-slider-wrapper{
    position: relative;
    height: rem-calc(150);
    overflow: hidden;

    .logo-carousel-slider{
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 400%;
      height: 100%;
      min-width: 100%;
      margin: 0 auto;
      transform: translate3d(0, 0, 0);
      animation: scrolling 30s linear infinite;

      // Wrapper for image
      div{
        flex: 1 0 rem-calc(250);
  

        img{
          max-width: rem-calc(250);
        }
      }
    }
  }

  @include breakpoint(large){
    padding: rem-calc(90 40 100);

    // Section heading
    .heading{
      font-size: rem-calc(38);

      br{
        display: inline-block;
      }
    }

    // Button below logo slider
    .button{
      padding-left: 3.5em;
      padding-right: 3.5em;
    }
  }
}

@keyframes scrolling {
  0%   { 
    left: 100%;
  }
  100% { 
    left: -350%;
  }
 }

// Main content area styles
.content-text{

  // Color for bolded paragraph text
  p > strong{
    color: #020086;
  }
}

// News articles in main content area on homepage
.content.home-page{

  hr{
    width: rem-calc(45);
    margin: 0 auto rem-calc(30) 0;
    border-bottom: 2px solid $secondary-color;
  }

  .content-sub .content-sub-text{

    // Section heading
    h2{
      & + hr{
        margin: 0 auto 0 0;
      }
    }

    .articles-list article{
      border-bottom: 1px solid #f4dae8;

      // Article title
      h2.article-header{
        font-size: rem-calc(18);
        font-weight: 500;
        
        a{
          color: #333;
        }
      }

      // Article date/time
      .article-meta{
        color: #a5a5a5;
        font-size: rem-calc(14);
        font-weight: $global-weight-bold;
        text-transform: uppercase;
      }
    }
  }
}

// Locations subpages
.locations-page{
  padding: rem-calc(30 0 0);

  // Click-to-call links
  a[href^=tel]{
    color: inherit;
    text-decoration: none;
  }

  h2:first-of-type{
    @extend h1;
  }

  @include breakpoint(large){
    padding: rem-calc(70 0 100);

    .additional-content-area{
      padding-bottom: rem-calc(100);
    }

    // Main content area
    .row.collapse.large-unstack{
      padding-top: rem-calc(100);
      border-top: 1px solid #f0cee0;
    }
  }
}

// Services callout blocks on Locations subpages
.location-blocks{
  padding: rem-calc(30 15);
  background: url(/images/location-blocks-bg.jpg) no-repeat center top;
  background-size: cover;

  .row .columns{
    border-bottom: 1px solid $white;
  }

  // Section heading
  .main-heading{
      margin: rem-calc(0 0 70);
      color: $white;
      font-family: $header-font-family;
      font-size: rem-calc(38);
      font-weight: $global-weight-bold;
      text-align: center;
  }
  
  // Individual callout
  .callout-block{
      text-align: center;

      hr{
          width: rem-calc(45);
          margin: rem-calc(30) auto;
          border-bottom: 2px solid $white;
      }

      .heading{
          margin: rem-calc(20 0 0);
          color: $white;
          font-size: rem-calc(32);
          font-weight: 600;
      }

      .message{
          margin: rem-calc(0 0 30);
          color: $white;
      }

      .button{
          margin: rem-calc(0 15 30);
      }
  }

  @include breakpoint(large){
      padding: rem-calc(60 0);

      // Lines between callouts
      .row .columns{
          border: none;

          &:nth-child(1), &:nth-child(2){
              border-right: 1px solid $white;
              border-bottom: 1px solid $white;
          }

          &:nth-child(3){
              border-bottom: 1px solid $white;
          }

          &:nth-child(4), &:nth-child(5){
              border-right: 1px solid $white;
          }
      }
  }

  @include breakpoint(xlarge){
      padding: rem-calc(100 0);

      // Individual callout
      .callout-block{
          padding: rem-calc(60);
      }
  }
}