// Sort Products Container
form .product_sort_dropdown_container {
	margin-bottom: rem-calc(30);
	label {
		display: inline-block;
		vertical-align: middle;
		margin-right: rem-calc(10);
		line-height: 33px;
		font-size: rem-calc(18);
		font-weight: 600;
		color: #000;
	}
	input[type="submit"].buttons {
		display: none;
	}
	#SortBox {
		border: 1px solid #d0d0d0;
		border-radius: 0;
		max-width: rem-calc(280);
		color: #9e9e9e;
		display: inline-block;
		vertical-align: middle;
		margin-bottom: rem-calc(20);
	}
}

// List Products
.product-grid {
	a {
		text-decoration: none;
		display: block;
	}

	.product-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto rem-calc(30);
		padding: rem-calc(30);
		background: #fff;
		border-bottom: 1px solid #f0cee0;
		text-align: center;

		@include breakpoint(large){
			flex-flow: row wrap;
			margin: 0 auto;
			padding: rem-calc(70 0);
		}

		// Container for image
		.thumbnail-container-wrapper{

			// Wrapper for image
			.thumbnail-container{
				display: flex;
				align-items: center;
				margin-bottom: rem-calc(20);
			}

			@include breakpoint(large){
				margin-right: rem-calc(130);
			}
		}

		// Container for text content
		.product-details-wrapper{
			flex: 1;
			text-align: left;

			// Product title
			.product_header.heading{
				color: #333;
				font-family: $body-font-family;
				font-size: rem-calc(32);
				font-weight: $global-weight-bold;
			}

			// Product info
			.product-description{

				strong{
					color: #868686;
					font-size: rem-calc(18);
					font-weight: $global-weight-normal;
				}

				ul li{
					font-weight: 400;
				}
			}

			@include breakpoint(large){
				.button-container{
					.button{
						max-width: rem-calc(291);
					}
				}
			}
		}

		img {
			display: block;
			margin: 0 auto;
			width: 100%;
			max-height: rem-calc(250);
			min-height: rem-calc(317);
		}

		.product-manufacturer{
			font-weight: 600;
			margin-bottom: rem-calc(5);
		}

		.product_header {
			margin-bottom: rem-calc(10);
			color: #000;
			font-size: rem-calc(22);
			font-weight: 400;
			line-height: 1.2;
			a{
                color: #1659ae;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
        }
        
        .product-description{
            margin-bottom: rem-calc(10);
            color: #444343;
            font-weight: 300;
        }

		.product_cost {
			margin-top: 0;
		}

		.product_code{
			font-style: normal;
			color: #717171;
			font-size: rem-calc(16);
		}

		.product_cost_view {
			color: #80c245;
			font-size: rem-calc(24);
			font-weight: 600;
		}

		// Cancels out include.css.php styles associated with products
		.product_imagecontainer {
			min-height: 0;
			display: block;
			vertical-align: initial;
		}
		.product_cost_box_standard {
			background: transparent;
			border: 0;
		}
		.product_cost {
			height: auto;
		}
	}
}

// Global MYCE Pagination Style
.paged_results {
	@include grid-column(12);
	margin-bottom: rem-calc(20);
	font-weight: 600;
	.page_selected {
		background: #80c245;
		color: #fff;
		padding: rem-calc(0 3);
	}
	.page_notselected {
		text-decoration: none;
	}
	.see-all-products {
		margin-left: rem-calc(15);
		text-decoration: none;
	}
}

// Product Detail Page
.product .callout.success, .close-button{
	color: #000;
	a{
		color: #555;
	}
}
.product-img-container {
	background: #fff;
	margin: rem-calc(0 0 20 0);
    text-align: center;
    border: rem-calc(6) solid #fff;
    box-shadow: inset 0 0 9px 1px #f4f4f4,0px 1px 1px 0px rgba(0,0,0,0.22);
    li {
    	padding-bottom: rem-calc(0);
    }
    @include breakpoint(large){
    	margin-right: rem-calc(30);
    }
}
.product-name {
	font-family: $header-font-family;
	font-size: rem-calc(30);
	margin-bottom: rem-calc(0);
	h1{
		color: #000;
		font-family: $header-font-family;
		font-size: rem-calc(30);
		margin-bottom: rem-calc(20);
	}
}
.product-details-header {
	@extend h1;
	color: #000;
}
.product-details-content{
	p{
		color: #111;
	}
}
.product-codes{
	color: #052b5f;
	font-weight: 700;
	margin-bottom: rem-calc(15);
	div{
		margin-bottom: rem-calc(5);
	}
}
.product-price {
	font-weight: 700;
	color: $secondary-color;
	font-size: rem-calc(24);
	margin-bottom: rem-calc(10);
}
.product-list-description {
	color: #57513f;
	font-size: rem-calc(18);
	font-weight: 700;
	margin: rem-calc(0 0 20 0);
	li {
		list-style: none;
		background: url('/images/icon-leaves.png') center left no-repeat;
		padding: rem-calc(5 0 5 28);
	}
}
.quantity {
	font-size: rem-calc(24);
	padding-top: rem-calc(20);
	color: #5b5b5b;
	input {
		display: inline-block;
		width: rem-calc(80);
		background: #eee;
		border: 1px solid rgba(0, 0, 0, 0.18);
		border-radius: 0;
		box-shadow: none;
		font-size: rem-calc(24);
		color: #5b5b5b;
		&:hover {
			cursor: pointer;
		}
	}
}
form .product button.submit {
	padding: rem-calc(20);
	color: #fff;
	font-size: rem-calc(18);
	font-weight: 700;
	background: $secondary-color;
	border: 1px solid #fff;
	transition: .3s;
	&:hover{
		background: darken($secondary-color, 15%);
	}
	img {
		margin-left: rem-calc(4);
		margin-top: rem-calc(-2);
	}
}
.us-only {
	display: inline-block;
	margin-left: rem-calc(10);
	color: #990000;
	font-weight: 700;
}
.product-variations {
	background: #f2f2f2;
	border: 1px solid #c1c1c1;
	border-bottom: 0;
	.variation {
		display: flex;
		flex-direction: column;
		padding: rem-calc(20);
		border-bottom: 1px solid #c1c1c1;
		transition: .1s;
		&:hover {
			background: darken(#f2f2f2, 10%);
		}
		input {
			float: left;
			display: inline-block;
			margin-top: rem-calc(4);
			margin-right: rem-calc(8);
		}
		.variation-name {
			flex-basis: 80%;
			font-size: rem-calc(18);
			font-weight: 700;
			color: #000;
			line-height: 1.2;
		}
		.variation-number {
			font-size: rem-calc(18);
			font-weight: 300;
			color: #505050;
			line-height: 1.2;
			margin-left: rem-calc(20);
		}
		.variation-price {
			flex-basis: 20%;
			color: $secondary-color;
			font-size: rem-calc(24);
			font-weight: 700;
			line-height: 1.2;
		}
		@include breakpoint(medium){
			flex-direction: row;
			.variation-price {
				margin-left: rem-calc(10);
				text-align: right;
			}
		}
	}
}

.product-in-box{
	border-top: 1px solid rgba(0,0,0,.2);
	padding: rem-calc(30 0);
	.product-info{
		p{
			font-size: rem-calc(16);
			font-family: $header-font-family;
		}
	}
	img{
		display: block;
		margin: 0 auto rem-calc(20);
	}
	.heading{
		width: 100%;
		color: $primary-color;
		font-size: rem-calc(30);
	}
	@include breakpoint(medium){
		display: flex;
  		align-items: center;
		.product-info{
			display: flex;
  			flex-direction: column;
  			justify-content: center;
			padding: rem-calc(0 0 0 30);
		}
		img{
			margin: 0;
			max-width: rem-calc(200);
		}
	}
}

.alert-box{
	border-style: solid;
	border-width: 1px;
	display: block;
	font-size: rem-calc(16);
	font-weight: 400;
	margin-bottom: 1.11111rem;
	padding: .77778rem 1.33333rem .77778rem .77778rem;
	position: relative;
	transition: opacity 300ms ease-out;
	background-color: #84c042;
	border-color: #72a637;
	color: #fff;
	&.success{
		background-color: #43AC6A;
	    border-color: #3a945b;
	    color: #fff;
	}
	&.alert{
		background-color: $alert-color;
	    border-color: darken($alert-color, 10%);
	    color: #fff;
	}
	.close{
		display: none;
	}
}

.product-parts-available{
	table.product-parts-available-table{
		background:#888;
		border-radius:0px;
		thead td{
			background: #565656;
			color: #fff;
		}
		th{
			text-align:center;
			color:#FFF;
			background:#444;	
			font-family: "Oswald", sans-serif;
			text-transform:uppercase;
			padding:16px;
		}
		td{
			font-size: rem-calc(18);
			background:#EEE;	
			text-align:center;
		}
	}
	.product-addon-quantitybox{
		display:inline;
		width:30px;
		margin-left:3px;	
	}
}